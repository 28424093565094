<template>
  <BaseLink
    v-if="to || href"
    ref="elementRef"
    :to="to"
    :href="href"
    :disabled="disabled"
    :class="['base-button', size, variant, { 'is-loading': loading }]"
  >
    <SvgIcon v-if="iconLeft" :svg="iconLeft" :size="iconSize" />
    <slot></slot>
    <SvgIcon v-if="iconRight" :svg="iconRight" :size="iconSize" />
  </BaseLink>
  <button
    v-else
    ref="elementRef"
    :type="type"
    :disabled="loading || disabled"
    :class="['base-button', size, variant, { 'is-loading': loading }]"
  >
    <SvgIcon v-if="iconLeft" :svg="iconLeft" :size="iconSize" />
    <slot></slot>
    <SvgIcon v-if="iconRight" :svg="iconRight" :size="iconSize" />
  </button>
</template>

<script setup lang="ts">
  import { type ButtonHTMLAttributes, type Component, ref } from 'vue';

  import BaseLink, { BaseLinkProps } from '@/shared/components/BaseLink.vue';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import { IconSize } from '@/shared/icons/types';

  export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'naked' | 'danger' | 'warning' | 'dark';
  export type ButtonSize = | 'small' | 'medium' | 'large';
  export type BaseButtonProps = {
    type?: ButtonHTMLAttributes['type']
    variant?: ButtonVariant
    loading?: boolean
    disabled?: boolean
    size?: ButtonSize
    iconLeft?: Component
    iconRight?: Component
    iconSize?: IconSize
  } & Pick<BaseLinkProps, 'href'| 'to'>;


  withDefaults(defineProps<BaseButtonProps>(), {
    type: 'button',
    variant: 'primary',
    loading: false,
    disabled: false,
    size: 'medium',
    iconLeft: undefined,
    iconRight: undefined,
    to: undefined,
    href: undefined,
    iconSize: 'small',
  });

  const elementRef = ref();

  const focus = async () => {
    elementRef.value.focus();
  };

  defineExpose({
    focus,
  });


</script>

<style lang="scss" scoped>
.base-button  {
  font-family: $type-sans-serif;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  color: var(--color, black);
  background-color: var(--bg-color, white);
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-weight: $font-weight-medium;
  min-width: max-content;

  &:hover {
    text-decoration: none;
  }

  &:focus-visible {
    outline-offset: unset;
  }

  &:hover, &.is-hovered {
    color: var(--color);
    box-shadow: 0px 4px 0px 0px var(--shadow-color);
  }

  &:focus, &.is-focused {
    outline: 3px solid var(--outline-color);
  }

  &:active, &.is-active {
    box-shadow: none;
    outline: 2px solid var(--outline-color);
  }

  &:disabled, &.is-disabled {
    cursor: not-allowed;
    box-shadow: none;

    &:active, &:focus {
      outline: none;
    }
  }

  &.is-fullwidth {
    justify-content: center;
    width: 100%
  }

  &.is-loading {
    color: transparent;
    pointer-events: none;
    display: flex;
    justify-content: center;
    &::after {
      animation: spinAround 500ms infinite linear;
      border: 2px solid hsl(0, 0%, 86%);
      border-radius: 9999px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      position: absolute;
      width: 1em;
    }
  }

  // Sizes
  &.small {
    height: 24px;
    padding: 5px 8px;
    font-size: 0.8rem;
  }

  &.medium {
    height: 36px;
    padding: 10px 16px;
    font-size: 1rem;
  }

  &.large {
    height: 56px;
    padding: 16px;
    font-size: 1.5rem;
  }

  // Variants
  &.primary {
    --color: #{$white};
    --bg-color: var(--brand-100);

    &:hover, &.is-hovered {
      --color: var(--brand-10);
      --shadow-color: var(--brand-30);
    }

    &:focus, &.is-focused {
      --color: #{$white};
      outline: 3px solid var(--brand-20);
    }

    &:active, &.is-active {
      --outline-color: var(--brand-30);
      --color: var(--brand-30);
      box-shadow: none;
    }

    &:not(.is-loading):disabled, &.is-disabled {
      --color: #{$white};
      --bg-color: var(--chrome-100);
    }
  }

  &.secondary {
    border: 1px solid var(--chrome-100);
    --color: var(--copy-100);
    --bg-color: #{$white};

    &:hover, &.is-hovered {
      --shadow-color: var(--chrome-50);
    }

    &:focus, &.is-focused {
      --outline-color: var(--copy-100);
    }

    &:active, &.is-active {
      outline: none;
      --bg-color: var(--chrome-30);
    }

    &:not(.is-loading):disabled, &.is-disabled {
      --color: var(--copy-30);
    }
  }

  &.tertiary {
    border: 1px solid var(--brand-100);
    --color: var(--brand-100);
    --bg-color:  #{$white};

    &:hover, &.is-hovered {
      --color: var(--brand-10);
      --bg-color: var(--brand-100);
      --shadow-color: var(--chrome-50);
    }

    &:focus, &.is-focused {
      --outline-color: var(--brand-100);
    }

    &:active, &.is-active {
      --outline-color: var(--brand-30);
      --color: var(--brand-30);
      --bg-color: var(--brand-100);
    }

    &:not(.is-loading):disabled, &.is-disabled {
      --color: var(--copy-30);
      --bg-color: #{$white};
      border: 1px solid var(--copy-30);
    }
  }

  &.naked {
    text-decoration: underline;
    font-weight: 400;
    border: none;
    --color: var(--brand-100);
    --bg-color: transparent;

    &:hover, &.is-hovered {
      --bg-color: var(--chrome-20);
    }

    &:focus, &.is-focused {
      --bg-color: var(--chrome-20);
      --outline-color: var(--brand-100);
      outline-offset: -2px;
    }

    &:active, &.is-active {
      --bg-color: var(--chrome-50);
      outline: none;
    }


    &:not(.is-loading):disabled, &.is-disabled {
      background: transparent;
      --color: var(--copy-30);
    }
  }

  &.danger {
    --color: #{$white};
    --bg-color: var(--danger-100);

    &:hover, &.is-hovered {
      --color: var(--danger-10);
      --shadow-color: var(--danger-30);
    }

    &:focus, &.is-focused {
      --color: #{$white};
      --outline-color: var(--danger-30);
    }

    &:active, &.is-active {
      --outline-color: var(--danger-30);
      --color: var(--danger-30);
      box-shadow: none;
    }

    &:not(.is-loading):disabled, &.is-disabled {
      --color: #{$white};
      --bg-color: var(--chrome-100);
    }
  }

  &.warning {
    --color: #{$white};
    --bg-color: var(--warning-100);

    &:hover, &.is-hovered {
      --color: var(--warning-10);
      --shadow-color: var(--warning-30);
    }

    &:focus, &.is-focused {
      --color: #{$white};
      --outline-color: var(--warning-30);
    }

    &:active, &.is-active {
      --outline-color: var(--warning-30);
      --color: var(--warning-30);
      box-shadow: none;
    }

    &:not(.is-loading):disabled, &.is-disabled {
      --color: #{$white};
      --bg-color: var(--chrome-100);
    }
  }

  &.dark {
    --color: #{$white};
    --bg-color: var(--copy-90);

    &:hover, &.is-hovered {
      --bg-color: var(--copy-70);
      --shadow-color: var(--copy-30);
    }

    &:focus, &.is-focused {
      --outline-color: var(--brand-20);
    }

    &:active, &.is-active {
      --outline-color: var(--copy-30);
    }

    &:not(.is-loading):disabled, &.is-disabled {
      --color: var(--copy-70);
      --bg-color: var(--copy-100);
    }
  }
}


</style>
