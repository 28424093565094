<script setup lang="ts">
  import { computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import ComboBox from '@/shared/components/ComboBox.vue';
  import { ListBoxCategory, ListBoxItem, ListBoxItemButton, ListBoxItemRouterLink } from '@/shared/components/list-box';
  import ListBoxSeparator from '@/shared/components/list-box/ListBoxSeparator.vue';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import CogIcon from '@/shared/icons/svgs/cog.svg';
  import ExternalLinkIcon from '@/shared/icons/svgs/external.svg';
  import SignOutIcon from '@/shared/icons/svgs/signOut.svg';
  import instrumentation from '@/shared/instrumentation';
  import { useModalManager } from '@/shared/modals/modalManager';
  import { OrgUser } from '@/shared/models';
  import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';

  import appInstrumentationTypes from '@App/instrumentation/types';
  import userMonitor from '@App/instrumentation/userMonitor';
  import PreferencesModal from '@App/layout/components/PreferencesModal.vue';
  import useDGFeature from '@DangerousGoods/behaviours/useDGFeature';
  import Org from '@Org/store/types';

  import HeaderButton from './HeaderButton.vue';

  const router = useRouter();
  const route = useRoute();
  const modalManager = useModalManager();

  const { isDGFeatureOn } = useDGFeature();

  const store = useStore();
  const user = computed<OrgUser>(() => store.getters[Org.getters.user]);

  const siteId = computed(() => (route.params.contextType === 'site' ? route.params.contextId : null));

  const preferencesModal = () => {
    modalManager.open(PreferencesModal, {});
  };

  const openUserGuide = () => {
    instrumentation.event(appInstrumentationTypes.USER_GUIDE_OPENED);
    router.open('/static/efm app - user guide 3.0.pdf');
  };

  const giveFeedback = () => {
    userMonitor.showFeedbackSurvey();
  };

  const vSensitive = createSensitiveDirective(true);
</script>

<template>
  <div class="user-context">
    <!-- todo: the v-if="siteId" should just hide the category not the whole menu -->
    <ComboBox v-if="siteId" data-testref="app-header-settings" left>
      <template #trigger>
        <HeaderButton class="settings">
          <SvgIcon :svg="CogIcon" size="small" />
        </HeaderButton>
      </template>
      <template #menu>
        <!--
        <ListBoxCategory title="Company Settings">
          <ListBoxItemRouterLink :to="{ path: '/' }">Manage users</ListBoxItemRouterLink>
        </ListBoxCategory>
        -->
        <ListBoxCategory v-if="siteId" title="Site settings">
          <ListBoxItemRouterLink :to="{ name: 'addressbook.manage' }">Address book</ListBoxItemRouterLink>
          <ListBoxItemRouterLink :to="{ name: 'shipping-item-presets.manage' }">Shipping item presets</ListBoxItemRouterLink>
          <ListBoxItemRouterLink v-if="isDGFeatureOn" :to="{ name: 'dangerous-goods-presets.manage' }">Dangerous goods presets</ListBoxItemRouterLink>
        </ListBoxCategory>
      </template>
    </ComboBox>
    <ComboBox data-testref="app-header-user" class="app-header-user" left>
      <template #trigger>
        <HeaderButton class="user-context-button">
          <div v-sensitive class="user-avatar">{{ user.fullName?.[0] }}</div>
          <div v-sensitive>{{ user.fullName }}</div>
        </HeaderButton>
      </template>
      <template #menu>
        <ListBoxCategory title="User settings">
          <ListBoxItemRouterLink v-if="route.params.contextType" :to="{ name: 'autoprint.config' }">
            Printer configuration
          </ListBoxItemRouterLink>
          <ListBoxItemButton data-testref="user-preferences" @click="preferencesModal">User preferences</ListBoxItemButton>
        </ListBoxCategory>
        <ListBoxItemButton
          data-testref="user-guide"
          :icon-right="ExternalLinkIcon"
          @click="openUserGuide"
        >
          User guide
        </ListBoxItemButton>
        <ListBoxSeparator />
        <ListBoxItemButton data-testref="give-feedback" @click="giveFeedback">Give feedback</ListBoxItemButton>
        <ListBoxSeparator />
        <ListBoxItemRouterLink
          :to="{ name: 'auth.logout' }"
          :icon-right="SignOutIcon"
          data-testref="logout"
        >
          Log out
        </ListBoxItemRouterLink>
        <ListBoxSeparator />
        <ListBoxItem>
          <p class="account-changes">
            To make changes to your account call
            <strong>efm</strong> on <strong>1300 769 605</strong>
          </p>
        </ListBoxItem>
      </template>
    </ComboBox>
  </div>
</template>

<style scoped lang="scss">
  .user-context {
    display: flex;
    margin-left: auto;
    gap: 12px;
  }

  .settings {
    width: 44px;
    justify-content: center;
    .svg-icon {
      color: var(--copy-70);
    }
  }

  .user-context-button {
    padding-left: 8px;
  }

  .user-avatar {
    font-size: 14px;
    background-color: var(--chrome-30);
    border-radius: 3px;
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: var(--copy-50);
  }

  .app-header-user .list-box {
    min-width: 280px;
  }

  .account-changes {
    white-space: normal;
    font-size: 14px;
    margin: 0;
    color: var(--copy-70);
    strong {
      font-weight: inherit;
      color: var(--copy-100);
    }
  }
</style>
