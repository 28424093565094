import DangerousGoods, { DeclarableDangerousGoods, NotifiableDangerousGoods } from '@/shared/models/DangerousGoods/DangerousGoods';
import DangerousGoodsPackingGroup from '@/shared/models/DangerousGoods/DangerousGoodsPackingGroup';
import { isNotifiable } from '@/shared/models/DangerousGoods/helpers';
import { createModelWithKey } from '@/shared/models/helpers/ModelWithKey';
import { components } from '@/shared/services/schema/geppetto-sender-app/dangerous-goods.schema';

type DeclarableDGResponse = components['schemas']['DeclarableDangerousGoods'];
type NotifiableDGResponse = components['schemas']['NotifiableDangerousGoods'];
type AnyDGResponse = DeclarableDGResponse | NotifiableDGResponse;

function mapDeclarableDGsFromAPI(data: DeclarableDGResponse): DeclarableDangerousGoods {
  return createModelWithKey({
    notifiable: false,
    presetId: data.presetId,
    unNumber: data.unNumber,
    properShippingName: data.properShippingName,
    technicalName: data.technicalName,
    productDescription: data.productDescription,
    classOrDivision: data.classOrDivision,
    subsidiaryRisks: data.subsidiaryRisks || [],
    packingGroup: data.packingGroup as DangerousGoodsPackingGroup | undefined,
    receptacle: data.receptacle,
    receptacleAggregateQuantity: data.receptacleAggregateQuantity,
    receptacleCount: data.receptacleCount,
    totalAggregateQuantity: data.totalAggregateQuantity,
  });
}

function mapNotifiableDGsFromAPI(data: NotifiableDGResponse): NotifiableDangerousGoods {
  return createModelWithKey({
    notifiable: true,
    presetId: data.presetId,
    unNumber: data.unNumber,
    properShippingName: data.properShippingName,
    technicalName: data.technicalName,
    productDescription: data.productDescription,
    receptacleAggregateQuantity: data.receptacleAggregateQuantity,
    notification: data.notification,
    receptacleCount: data.receptacleCount,
    totalAggregateQuantity: data.totalAggregateQuantity,
  });
}

function dangerousGoodsFromApiIsNotifiable(response: AnyDGResponse): response is components['schemas']['NotifiableDangerousGoods'] {
  return !!response.notifiable;
}

export function mapDangerousGoodsFromAPI(response: AnyDGResponse) : DangerousGoods {
  if (dangerousGoodsFromApiIsNotifiable(response)) {
    return mapNotifiableDGsFromAPI(response);
  }
  return mapDeclarableDGsFromAPI(response);
}

type DeclarableDGRequest = components['schemas']['DeclarableDangerousGoods'];
type NotifiableDGRequest = components['schemas']['NotifiableDangerousGoods'];
type AnyDGRequest = DeclarableDGRequest | NotifiableDGRequest;

function mapNotifiableDangerousGoodsToAPI(dg: NotifiableDangerousGoods): NotifiableDGRequest {
  return {
    notifiable: true,
    presetId: dg.presetId,
    unNumber: dg.unNumber,
    properShippingName: dg.properShippingName,
    technicalName: dg.technicalName,
    productDescription: dg.productDescription,
    receptacleAggregateQuantity: dg.receptacleAggregateQuantity,
    notification: dg.notification,
    receptacleCount: dg.receptacleCount,
    totalAggregateQuantity: dg.totalAggregateQuantity,
  };
}

function mapDeclarableDangerousGoodsToAPI(dg: DeclarableDangerousGoods): DeclarableDGRequest {
  return {
    notifiable: false,
    presetId: dg.presetId,
    unNumber: dg.unNumber,
    properShippingName: dg.properShippingName,
    technicalName: dg.technicalName,
    productDescription: dg.productDescription,
    classOrDivision: dg.classOrDivision,
    subsidiaryRisks: dg.subsidiaryRisks,
    packingGroup: dg.packingGroup,
    receptacle: dg.receptacle,
    receptacleAggregateQuantity: dg.receptacleAggregateQuantity,
    receptacleCount: dg.receptacleCount,
    totalAggregateQuantity: dg.totalAggregateQuantity,
  };
}

export function mapDangerousGoodsToAPI(dg: DangerousGoods): AnyDGRequest {
  if (isNotifiable(dg)) {
    return mapNotifiableDangerousGoodsToAPI(dg);
  }
  return mapDeclarableDangerousGoodsToAPI(dg);
}
