/**
 * Manifest model
 * represents a single manifest, including its status and the included consignments
 */

export const ManifestStatus = {
  Proposed: 'proposed',
  Approved: 'approved',
  Completed: 'completed',
  Rejected: 'rejected',
} as const;

export const StagedConsignmentStatus = {
  Staged: 'staged',
  Accepted: 'accepted',
  Cancelled: 'cancelled',
  Rejected: 'rejected',
} as const;

export type StagedConsignmentStatus = typeof StagedConsignmentStatus[keyof typeof StagedConsignmentStatus];

export interface StagedConsignment {
  id: UUID;
  status: StagedConsignmentStatus;
  code?: string;
  details?: string;
}
export interface Manifest {
  id: UUID;
  created: RFC3339InstantString;
  status: typeof ManifestStatus[keyof typeof ManifestStatus];
  consignmentType: string;
  siteId: UUID;
  dispatchDate: PlainDateString;
  consignments: StagedConsignment[];
  trackingNumber?: string;
}

const StagedConsignment = ({
  id,
  status,
  code,
}: StagedConsignment) => {
  // if status is 'rejected' then code should be defined
  if (status === StagedConsignmentStatus.Rejected && !code) {
    logger.warn('staged consignment rejected without a code', { consignmentId: id });
  }
  return { id, status, code };
};

const create = ({
  id,
  created,
  status,
  consignmentType,
  siteId,
  dispatchDate,
  consignments = [],
  trackingNumber,
} : Manifest) : Manifest => ({
  id,
  created,
  status,
  consignmentType,
  siteId,
  dispatchDate,
  consignments: consignments.map(StagedConsignment),
  // if status is 'completed' then trackingNumber should be included
  trackingNumber,
});

const createFromApi = ({
  id,
  attributes,
}: { id: UUID, attributes: Omit<Manifest, 'id'> }) => create({
  id,
  ...attributes,
});

export const manifestFactory = {
  create,
  createFromApi,
};

export default manifestFactory;
