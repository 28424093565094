<template>
  <div class="form-field" :class="{ 'is-dirty': dirty, 'is-compact': compact, 'has-error': displayErrorMessages.length > 0 }">
    <div v-if="label || infoHelp || subLabel || hint || optional" class="form-field-header">
      <div v-if="label || infoHelp || subLabel" class="form-field-label-container">
        <label class="form-field-label is-text-h3" :for="inputIdentifier">
          {{ label }}
        </label>
        <Tooltip v-if="infoHelp" class="form-field-tooltip" multilined :position="`is-${infoHelpPosition}`">
          <SvgIcon :svg="IconHelp" size="small" /><template #content>{{ infoHelp }}</template>
        </Tooltip>
        <span v-if="subLabel" class="form-field-sublabel is-text-subhead2">{{ subLabel }}</span>
      </div>
      <span v-if="optional || hint" class="form-field-hint is-text-subhead2">{{ optional ? '(optional)' : hint }}</span>
    </div>

    <div class="form-field-body">
      <div class="form-field-control" :data-error="inputProps.hasError">
        <slot v-bind="inputProps"><!-- input slot --></slot>
      </div>

      <WarningTooltip v-if="warnIf && warningMessage" class="form-field-warning">
        {{ warningMessage }}
      </WarningTooltip>

      <div class="form-field-error hidden-if-empty">
        <slot name="errors" :errors="errors" :loading="loading" :display-error-messages="displayErrorMessages">
          <p v-for="(errorMessage, index) in errorMessages" :key="index" class="field-error is-danger">{{ sentenceCase(errorMessage) }}</p>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  import uniqueId from 'lodash/uniqueId';

  import { markRaw } from 'vue';

  import Tooltip from '@/shared/components/Tooltip.vue';
  import WarningTooltip from '@/shared/components/WarningTooltip.vue';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import IconHelp from '@/shared/icons/svgs/help.svg';
  import InputValidateOnMixin from '@/shared/mixins/InputValidateOnMixin';
  import { sentenceCase } from '@/shared/utils/string-utils';
  import SingleValidationMixin from '@/shared/validation/SingleValidationMixin';


  export default {
    name: 'FormFieldWrapper',
    components: { Tooltip, SvgIcon, WarningTooltip },
    mixins: [SingleValidationMixin, InputValidateOnMixin],
    props: {
      label: { type: String, default: null },
      subLabel: { type: String, default: null },
      hint: { type: String, default: null },
      infoHelp: { type: String, default: null },
      infoHelpPosition: { type: String, default: 'top', validator: (dir) => ['top', 'bottom', 'left', 'right'].includes(dir) },
      loading: { type: Boolean, default: false },
      optional: { type: Boolean, default: false },
      warnIf: { type: Boolean, default: true },
      warningMessage: { type: String, default: null },
      compact: { type: Boolean, default: false },
      displayAllValidations: { type: Boolean, default: false },
    },
    data: () => ({ IconHelp: markRaw(IconHelp) }),
    computed: {
      inputIdentifier() {
        return `input-${uniqueId()}`;
      },
      inputProps() {
        return {
          id: this.inputIdentifier,
          hasError: this.displayErrorMessages.length > 0,
          loading: this.loading,
          ...this.safeInputListeners,
        };
      },
      errorMessages() {
        return this.displayAllValidations ? this.displayErrorMessages : [this.displayErrorMessages[0]];
      },
    },
    methods: {
      sentenceCase,
      touch() {
        this.validation?.$touch?.();
      },
      reset() {
        this.validation?.$reset?.();
      },
    },
  };
</script>

<style lang="scss" scoped>
.form-field {
  &.is-compact {
    .form-field-body {
      display: inline-grid;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.form-field-header {
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
  justify-content: space-between;
  color: var(--copy-100);
}

.form-field-label-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0 4px;
}

.form-field-label {
  white-space: nowrap;
}

.form-field-tooltip {
  align-self: center;

  & :deep(.tooltip-trigger) {
    display: flex; // fix svg inline layout taking up more vertical space than the icon
    color: var(--brand-100);
  }
}

.form-field-sublabel {
  color: var(--copy-70);
}

.form-field-hint {
  color: var(--copy-70);
  white-space: nowrap;
}

.form-field-body {
  display: grid;
  gap: 0 16px;
  justify-items: start;
  align-items: center;
  grid-template-areas:
    "control"
    "error";

  &:has(.form-field-warning) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
    "control warning"
    "error .";
  }
}

.form-field-control {
  grid-area: control;
  justify-self: stretch;
}

.form-field-warning {
  grid-area: warning;
}

.form-field-error {
  grid-area: error;
  justify-self: start;
  font-size: 0.75rem;
  color: var(--danger-100);

  p + p {
    margin-top: 0;
  }
}

.help:first-letter {
  text-transform: uppercase;
}
</style>
