import { ManifestResultSet } from '@/shared/models';
import ConsignmentToBeManifested from '@/shared/models/ConsignmentToBeManifested';
import { Manifest, manifestFactory } from '@/shared/models/Manifest';
import polly from '@/shared/polly';
import ApiClient, { GeppettoApiPaginatedResponse } from '@/shared/services/api-client';
import { formatSearchParams, FormatSearchParamsArgs } from '@/shared/services/helpers';
import SiteIdLimitError from '@/shared/services/sender/errors/SiteIdLimitError';
import { mapConsignmentsToBeManifested } from '@/shared/services/sender/mappers/manifestMappers';

export interface ManifestClientConfig {
  baseUrl: string;
  maxSiteIdParams: number;
}

 // TODO: we should use the generated type from the API schema
 // but current manifest schema is out of sync
interface FetchManifestResponse {
  data: {
    id: UUID;
    attributes: Manifest;
  }
}

export default class ManifestClient {
  private apiClient: ApiClient;

  private config: ManifestClientConfig;

  constructor(apiClient: ApiClient, config: ManifestClientConfig) {
    this.apiClient = apiClient;
    this.config = config;
  }


  async create(consignmentsToBeManifested: ConsignmentToBeManifested[]) {
    if (consignmentsToBeManifested.length === 0) {
      throw (new Error('consignments are required to create manifest'));
    }

    const attributes = mapConsignmentsToBeManifested(consignmentsToBeManifested);

    try {
      const response = await this.apiClient.post<FetchManifestResponse>('/v0/manifests', {
        data: {
          attributes,
        },
      });

      return manifestFactory.createFromApi(response.data.data);
    } catch (error) {
      logger.error('[SenderService] Manifest create failed', { attributes, error });
      throw error;
    }
  }

  async fetchList({
                        limit,
                        offset,
                        sort = '-created',
                        filters,
                      }: FormatSearchParamsArgs) {
    const formattedFilters = { ...filters };

    // if an empty agreed service id list is given, return nothing.
    if (filters?.['agreedService.id'] === '') return ManifestResultSet.create();

    if (filters?.created) {
      formattedFilters.created = `[${formattedFilters.created}]`;
    }

    const params = formatSearchParams({ limit, offset, sort, filters: formattedFilters });

    // this filter is required to determine whether the user is authorised to
    // retrieve the matching resources
    if (!params['filter[site.id]']) {
      // return a silent empty result set
      return ManifestResultSet.create();
    }

    // we have a limit of n site IDs
    const maxSiteIds = this.config.maxSiteIdParams as number;
    if (maxSiteIds && params['filter[site.id]'] && params['filter[site.id]'].split(',').length > maxSiteIds) {
      throw new SiteIdLimitError(params['filter[site.id]'].split(',').length, maxSiteIds);
    }

    const { data } = await this.apiClient.query<GeppettoApiPaginatedResponse<unknown>>('/v0/manifests', { params });

    return ManifestResultSet.createFromApi(data);
  }

  /*
  * DOCUMENTS
  */

  async fetchById({ id }: { id: string }) {
    const response = await this.apiClient.get<FetchManifestResponse>(`/v0/manifests/${id}`);

    return manifestFactory.createFromApi(response.data.data);
  }

  private async pollDocumentStatus(docPath: string) {
    return polly(() => this.apiClient.head(docPath));
  }

  private async getDocumentUrlWhenReady(path: string, baseUrl = false) {
    const params = (new URLSearchParams()).toString();
    const documentPath = `${path}${params && `?${params}`}`;
    await this.pollDocumentStatus(documentPath);
    return `${baseUrl ? this.config.baseUrl : ''}${documentPath}`;
  }


  async getManifestDocumentUrl(manifestId: UUID, documentFormat = 'pdf', baseUrl = false) {
    return this.getDocumentUrlWhenReady(`/v0/manifests/${manifestId}/document${documentFormat === 'pdf' ? '.pdf' : ''}`, baseUrl);
  }
}
