import { Temporal } from '@js-temporal/polyfill';

import { CustomFieldDataType, CustomFieldViewSchema } from '@/shared/customFields/types';
import { consignmentAddresseeFactory, ConsignmentReceiver, ConsignmentSender, NewConsignmentReceiver } from '@/shared/models/ConsignmentAddressee';
import type DangerousGoodsDeclaration from '@/shared/models/DangerousGoods/DangerousGoodsDeclaration';
import { MOVEMENT_FLOW_TYPES_RECORD, MovementFlowType } from '@/shared/models/MovementFlow';
import type { ConsignmentPalletDocketNumbers, PalletTransferType } from '@/shared/types/ConsignmentPallets';

import { components } from '../services/schema/geppetto-sender-app/consignments.schema';

import AgreedService from './AgreedService/AgreedService';
import ConsignmentLineItem, { consignmentLineItemFactory, NewConsignmentLineItem } from './ConsignmentLineItem';
import { ConsignmentProofOfDeliveryEvent, ConsignmentTrackingEvent, consignmentTrackingEventFactory, isProofOfDeliveryEvent } from './ConsignmentTrackingEvent';


export type ConsignmentStatus = 'started' | 'completed' | 'unmanifested' | 'manifested';

export const CONSIGNMENT_STATUS: Record<string, ConsignmentStatus> = {
  STARTED: 'started',
  COMPLETED: 'completed',
  UNMANIFESTED: 'unmanifested',
  MANIFESTED: 'manifested',
};

export type ConsignmentType = MovementFlowType;
export const CONSIGNMENT_TYPES = MOVEMENT_FLOW_TYPES_RECORD;


export interface ConsignmentPalletData {
  transferType: PalletTransferType;
  docketNumbers: ConsignmentPalletDocketNumbers;
}

export interface ConsignmentLabelOptions {
  newItemsOnly?: string; // boolean
  generateInstructions?: string; // boolean
}

interface LabelDocumentDetails {
  params: ConsignmentLabelOptions,
  meta?: {
    isDefault: boolean;
  },
}

export interface ConsignmentLabelDocuments {
  allLabels: LabelDocumentDetails | null;
  newLabels: LabelDocumentDetails | null;
}
export interface ConsignmentManifest {
  id: UUID;
  addedAt: Temporal.Instant;
}
export interface ConsignmentTransfer {
  transferredBy: components['schemas']['ConsignmentTransferSummary']['transferredBy'];
  transferredAt?: Temporal.Instant;
}

export type ConsignmentTransferPolicy = components['schemas']['ConsignmentTransferPolicy'];

export default interface Consignment {
  id: UUID;
  createdAt: string,
  source?: {
    applicationName: string;
  };
  consignmentNo?: string;
  costCenter?: string;
  dispatchDate: PlainDateString;
  // dispatchDate: PlainDate;
  lineItems: ConsignmentLineItem[];
  manifest?: ConsignmentManifest,
  transfer?: ConsignmentTransfer;
  payerAccount?: string;
  quoteId?: UUID;
  agreedService: Partial<AgreedService> | { id : UUID}; // has to be a partial because we will decorate it at the later stage in useConsignmentFetch
  receiver: ConsignmentReceiver;
  references: string[];
  sender: ConsignmentSender;
  siteId?: UUID; // TODO: this probably shouldn't be optional
  status?: ConsignmentStatus;
  tracking: ConsignmentTrackingEvent[];
  type: ConsignmentType;
  updatedAt?: RFC3339InstantString;
  // updatedAt?: Instant;
  pallets?: ConsignmentPalletData;
  estimateId?: UUID;
  orderId?: UUID;
  dangerousGoodsDeclaration?: DangerousGoodsDeclaration;
  issues?: Record<string, number>;
  transferPolicy: ConsignmentTransferPolicy;
  linkedOrders: components['schemas']['ViewConsignment']['linkedOrders']
  customFields?: CustomFieldViewSchema[];

  // derived
  readonly pods: ConsignmentProofOfDeliveryEvent[];
  readonly items: Record<string, ConsignmentLineItem & { ordinal: number }>;
}

function deriveItemsFromLineItems(lineItems: ConsignmentLineItem[]) {
  return lineItems
    // Derive a list of itemIds, in order, based on the order of lineItems and itemIds in each lineItem.
    // This order is preserved by the back-end, so always guarantees the same result.
    .reduce<string[]>((all, lineItem) => (lineItem.itemIds ? [...all, ...lineItem.itemIds] : all), [])
    // Key the resulting object by itemId for easier access and to match other models
    .reduce<Record<string, ConsignmentLineItem & { ordinal: number }>>((all, itemId: UUID, index: number) => ({
      ...all,
      [itemId]: {
        ...lineItems.find(lineItem => lineItem.itemIds.includes(itemId)) as ConsignmentLineItem,
        // Add a consistent, unique ordinal for each item based on the order of lineItems / itemIds.
        // This can be used to display "Item 1", "Item 2" against scan events.
        ordinal: index + 1,
      },
    }), {});
}

function create({
  id,
  createdAt,
  source,
  consignmentNo,
  type,
  references,
  dispatchDate,
  sender,
  receiver,
  costCenter,
  payerAccount,
  lineItems,
  quoteId,
  agreedService,
  siteId,
  status,
  manifest,
  transfer,
  tracking,
  updatedAt,
  pallets,
  estimateId,
  orderId,
  dangerousGoodsDeclaration,
  issues,
  transferPolicy,
  linkedOrders,
  customFields,
}: Omit<Consignment, 'pods' | 'items'>): Consignment {
  const lineItemModels = lineItems.map(consignmentLineItemFactory.create);
  const trackingEventModels = tracking.map(consignmentTrackingEventFactory.create);
  const podEventModels = trackingEventModels.filter(isProofOfDeliveryEvent);

  return {
    id,
    createdAt,
    source,
    consignmentNo,
    type,
    references,
    dispatchDate,
    sender,
    receiver,
    costCenter,
    payerAccount,
    quoteId,
    agreedService,
    siteId,
    status,
    manifest,
    transfer,
    updatedAt,
    customFields,

    // mapped fields
    lineItems: lineItemModels,
    tracking: trackingEventModels,

    // derived fields
    pods: podEventModels,
    items: deriveItemsFromLineItems(lineItemModels), // Only present once consignment has been persisted in the back-end

    pallets,
    estimateId,
    orderId,
    dangerousGoodsDeclaration,
    issues,
    transferPolicy,
    linkedOrders,
  };
}

export interface NewConsignment {
  id?: UUID;
  agreedService?: Partial<AgreedService>
  transferPolicy?: components['schemas']['ConsignmentTransferPolicy'];
  costCenter?: string;
  dispatchDate?: PlainDateString; // PlainDate;
  lineItems: NewConsignmentLineItem[];
  payerAccount?: string;
  quoteId?: UUID;
  receiver: NewConsignmentReceiver;
  references: string[];
  sender: ConsignmentSender;
  siteId?: UUID;
  type?: ConsignmentType;
  pallets?: ConsignmentPalletData;
  estimateId?: UUID;
  orderId?: UUID;
  dangerousGoodsDeclaration: DangerousGoodsDeclaration;
  customFields?: Record<UUID, CustomFieldDataType>;
}

const createNew = ({
  id,
  agreedService,
  transferPolicy,
  costCenter,
  dispatchDate,
  lineItems,
  payerAccount,
  quoteId,
  receiver,
  references,
  sender,
  siteId,
  type,
  pallets,
  estimateId,
  orderId,
  customFields,
}: Partial<NewConsignment> = {}): NewConsignment => ({
  id,
  agreedService,
  transferPolicy,
  references: references || [],
  costCenter,
  dispatchDate,
  lineItems: lineItems || [],
  payerAccount,
  quoteId,
  receiver: receiver || consignmentAddresseeFactory.createNewReceiver(),
  sender: sender || consignmentAddresseeFactory.createNewSender(),
  siteId,
  type,
  pallets,
  estimateId,
  orderId,
  // new consignments and consignments loaded and mapped to new consignments to be set as initial form data
  // must have the user declaration unset
  dangerousGoodsDeclaration: { excludesDangerousGoods: false },
  customFields,
});

export const consignmentFactory = {
  create,
  createNew,
};
