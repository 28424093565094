import type { Estimate, EstimateUpdated, MovementFlowType, NewEstimate } from '@/shared/models';
import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import ApiClient from '@/shared/services/api-client';
import { ApiClientRequestConfig } from '@/shared/services/api-client/types';
import { formatSearchParams } from '@/shared/services/helpers';
import { operations, paths } from '@/shared/services/schema/geppetto-sender-app/estimates.schema';

import SiteIdLimitError from '@/shared/services/sender/errors/SiteIdLimitError';

import {
  mapEstimateFetchResponseToEstimate,
  mapEstimateResponseToEstimateUpdated,
  mapEstimateSearchResponseToEstimateResultSet,
  mapEstimateToEstimateCreateRequest,
} from './mappers/estimateMappers';

interface EstimateClientConfig {
  resourcesPerPage: number;
  maxSiteIdParams: number;
}

export interface EstimateSearchParams {
  limit?: number;
  offset?: number;
  sort?: string;
  filters: {
    siteId: string;
    createdAt?: [string, string];
    type?: MovementFlowType[],
    hasDangerousGoods?: boolean;
  }
  search?: string | null;
}

export default class EstimateClient {
  private apiClient: ApiClient;

  private config: EstimateClientConfig;

  constructor(apiClient: ApiClient, config: EstimateClientConfig) {
    this.apiClient = apiClient;
    this.config = config;
  }

  public async create(estimate: NewEstimate): Promise<EstimateUpdated> {
    const request = mapEstimateToEstimateCreateRequest(estimate);

    const response = await this.apiClient.post<operations['createEstimate']['responses']['201']['content']['application/json']>(
      '/v0/estimates',
      { data: request },
    );

    logger.debug('[EstimateService] Estimate created', {}, { request, response });

    return mapEstimateResponseToEstimateUpdated(response.data.data);
  }

  public async update(id: UUID, estimate: NewEstimate): Promise<EstimateUpdated> {
    const request = mapEstimateToEstimateCreateRequest(estimate);

    const response = await this.apiClient.put<operations['updateEstimate']['responses']['200']['content']['application/json']>(
      `/v0/estimates/${id}`,
      { data: request },
    );

    logger.debug('[EstimateService] Estimate updated', {}, { estimateId: id, request, response });

    return mapEstimateResponseToEstimateUpdated(response.data.data);
  }

  /**
   * Signal to the back-end that the user is ready to "Save" the estimate with one or more selected quotes.
   * @param id The id of the estimate to save
   * @param selectedQuoteIds An array of one or more quote ids that the user has selected.
   */
  public async save(id: UUID, selectedQuoteIds: UUID[]): Promise<void> {
    const response = await this.apiClient.post<operations['saveEstimate']['responses']['204']['content']>(
      `/v0/estimates/${id}/save`,
      {
        data: {
          selectedQuoteIds,
        },
      },
    );

    logger.debug('[EstimateService] Estimate saved', {}, { estimateId: id, response });
    // no throw, success!
  }

  public async fetch(id: UUID, config?: ApiClientRequestConfig): Promise<Estimate> {
    const response = await this.apiClient.get<operations['viewEstimate']['responses']['200']['content']['application/json']>(
      `/v0/estimates/${id}`,
      config,
    );

    logger.debug('[EstimateService] Estimate fetched', {}, { estimateId: id, response });

    return mapEstimateFetchResponseToEstimate(response.data.data);
  }

  public async delete(id: UUID): Promise<void> {
    const response = await this.apiClient.delete<paths['/estimates/{id}']['delete']['responses']['204']>(
      `/v0/estimates/${id}`,
    );

    logger.debug('[EstimateService] Estimate deleted', {}, { estimateId: id, response });
    // no throw, success!
  }

  public async search({
                        limit = this.config.resourcesPerPage,
                        offset = 0,
                        sort = '-updatedAt',
                        filters,
                        search,
                      }: EstimateSearchParams, config?: ApiClientRequestConfig): Promise<PaginatedItems<Estimate>> {
    const formattedFilters: Record<string, unknown> = { ...filters };

    const params = formatSearchParams({
      limit, offset, sort, search, filters: formattedFilters,
    });

    if (params['filter[createdAt]']) {
      // date range expressed as an array of two Instants
      const [createdAtStart, createdAtEnd] = params['filter[createdAt]'];
      params['filter[createdAt]'] = `[${createdAtStart},${createdAtEnd}]`;
    }

    if (!params['filter[siteId]'] || params['filter[siteId]'].length < 1) {
      throw new Error('siteId is a required parameter for estimate search');
    }
    if (this.config.maxSiteIdParams
      && Array.isArray(params['filter[siteId]'])
      && params['filter[siteId]'].length > this.config.maxSiteIdParams) {
      throw new SiteIdLimitError(params['filter[siteId]'].length, this.config.maxSiteIdParams);
    }

    // @see https://flip-eng.atlassian.net/browse/GEPPIE-6320
    // if (params.q) {
    //   params['highlight[start]'] = '{{';
    //   params['highlight[stop]'] = '}}';
    // }

    const response = await this.apiClient.query<operations['getEstimates']['responses']['200']['content']['application/json']>(
      '/v0/estimates',
      {
        ...config,
        params,
      },
    );

    logger.debug('[EstimateService] Estimates searched', {}, { params, response });

    return mapEstimateSearchResponseToEstimateResultSet(response.data);
  }
}
