import { components } from '@/shared/services/schema/geppetto-sender-app/consignments.schema';

export type ScanEvent = components['schemas']['ScanEvent'];
export type ProofOfDeliveryEvent = components['schemas']['ProofOfDeliveryEvent'];

type TrackingEvent = components['schemas']['TrackingEvent'];

type TrackingEventData = ProofOfDeliveryEvent | ScanEvent;
export type ConsignmentTrackingEvent = Omit<TrackingEvent, 'data'> & {
  data: {
    description: string;
    documentId?: string;
    extraInfo?: string;
  }
};

export type ConsignmentProofOfDeliveryEvent = ConsignmentTrackingEvent & {
  type: 'proofOfDelivery', data: {
    description: string;
    documentId: string;
  }
};

export const isProofOfDeliveryEvent = (event: ConsignmentTrackingEvent): event is ConsignmentProofOfDeliveryEvent => event.type === 'proofOfDelivery' && !!event.data.documentId;


const mapData = (type: TrackingEvent['type'], data: TrackingEventData): ConsignmentTrackingEvent['data'] => {
  if (type === 'proofOfDelivery') {
    return { ...data, description: 'POD available' };
  }

  return { ...data } as ScanEvent;
};

const create = (event: TrackingEvent): ConsignmentTrackingEvent => ({
  ...event,
  data: mapData(event.type, event.data),
});

const createFromApi = create;

export const consignmentTrackingEventFactory = {
  create,
  createFromApi,
};


export default consignmentTrackingEventFactory;
