import { Temporal } from '@js-temporal/polyfill';

import { TimeRange } from '@/shared/DateTime';
import { AgreedService, PackagingType } from '@/shared/models';
import { createAgreedService } from '@/shared/models/AgreedService/AgreedService';
import { createOrganisation } from '@/shared/models/Organisation';
import OrgUser, { createOrgUser } from '@/shared/models/OrgUser';
import { createPackagingType } from '@/shared/models/PackagingType';
import Site, { createSite } from '@/shared/models/Site';

import { AgreedServiceResponse, FetchUserResponse, PackagingTypeResponse, SiteResponse } from '../types/FetchUser';

const mapAgreedService = (response: AgreedServiceResponse): AgreedService => createAgreedService({
  id: response.uuid,
  name: response.name,
  carrierChargeAccount: response.carrierChargeAccount,
  carrierServiceId: response.carrierServiceId,
  carrierServiceProviderType: response.carrierServiceProviderType,
  carrier: {
    id: response.carrier.uuid,
    name: response.carrier.name,
  },
});

const mapPackagingType = (response: PackagingTypeResponse): PackagingType => createPackagingType({
  name: response.name,
});

const mapPalletManagement = (response: SiteResponse['palletManagement']): Site['palletManagement'] => ({
    enabled: response?.enabled || false,
    preferredTransferType: response?.preferredTransferType && response?.preferredTransferType !== 'none' ? response?.preferredTransferType : undefined,
    allowedPalletTypes: response?.allowedPalletTypes || [],
  });

export const mapSite = (response: SiteResponse): Site => {
  let defaultPickupWindow;
  try {
    if (response.siteAccess.defaultPickupWindow?.start && response.siteAccess.defaultPickupWindow?.duration) {
      defaultPickupWindow = TimeRange.from({
        start: Temporal.PlainTime.from(response.siteAccess.defaultPickupWindow.start),
        duration: Temporal.Duration.from(response.siteAccess.defaultPickupWindow.duration),
      });
    }
  } catch (error) {
    logger.error('Failed mapping defaultPickupWindow for site', error);
  }

  return createSite({
    id: response.uuid,
    name: response.name,
    displayName: response.displayName,
    label: response.label,
    addressId: response.streetAddressId,
    agreedServices: response.agreedServices?.map(mapAgreedService),
    costCentreConfiguration: {
      costCentres: response.costCentreConfiguration.costCentres || [],
      required: response.costCentreConfiguration.required,
    },
    packagingTypes: response.packagingTypes?.map(mapPackagingType),
    // todo: also pass the packagingTypes array to help with the look-up
    organisation: createOrganisation({
      id: response.organisation.uuid,
      name: response.organisation.name,
    }),

    // site level features
    orderConsolidationEnabled: response.orderConsolidationEnabled,
    deliveryTimeSlotsEnabled: response.deliveryTimeSlotsEnabled,
    consignmentImportConfiguration: response.consignmentImportConfiguration,
    dangerousGoods: {
      isTransacting: !!response.dangerousGoods?.isTransacting,
      hasTransacted: !!response.dangerousGoods?.hasTransacted,
    },
    userCanTransactWithDangerousGoods: response.userCanTransactWithDangerousGoods,
    palletManagement: mapPalletManagement(response.palletManagement),
    siteAccess: {
      defaultPickupWindow,
    },
    orderConfiguration: response.orderConfiguration,
    devFlags: response.devFlags,
  });
};

export const mapFetchUserToOrgUser = (response: FetchUserResponse): OrgUser => createOrgUser({
  id: response.uuid,
  username: response.username,
  fullName: response.fullName,
  phone: response.phone,
  email: response.email,
  acceptedTermsAt: response.acceptedTermsAt ? new Date(Date.parse(response.acceptedTermsAt)) : undefined,
  sites: response.sites.map(mapSite),
});

