import clamp from 'lodash/clamp';
import cloneDeep from 'lodash/cloneDeep';

import { PickupService } from '@/shared/models';
import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import ListingFilter from '@/shared/models/ListingFilters';
import Pickup from '@/shared/models/Pickup/Pickup';
import { PickupConsignment } from '@/shared/models/Pickup/PickupConsignment';
import PickupFormData from '@/shared/models/Pickup/PickupFormData';
import PickupServiceOption from '@/shared/models/Pickup/PickupServiceOption';
import PickupSummary from '@/shared/models/Pickup/PickupSummary';
import ApiClient, { ApiClientRequestConfig, ApiClientResponse, ValidationError } from '@/shared/services/api-client';
import { formatSearchParams } from '@/shared/services/helpers';
import { components, operations } from '@/shared/services/schema/geppetto-sender-app/pickups.schema';
import SiteIdLimitError from '@/shared/services/sender/errors/SiteIdLimitError';

import {
  mapCreatePickupServerErrors,
  mapPickupConsignmentUpdated,
  mapPickupFormDataToPickupSaveRequest,
  mapPickupGetResponse,
  mapPickupSearchResponse,
  mapPickupServiceOption,
} from './mappers/pickupMappers';

export interface PickupListParams extends Omit<ListingFilter, 'filters'> {
  filters?: {
    pickupWindow?: string; // Interval format
    'service.agreedServiceId'?: string;
    type?: components['schemas']['ConsignmentType'][];
    hasDangerousGoods?: boolean;
    siteId?: string;
    requestedAt?: string;
  }
}

export interface PickupUpdateResponse {
  id: UUID,
  serviceOptions: PickupServiceOption[];
  consignments?: PickupConsignment[];
}

interface PickupClientConfig {
  resourcesPerPage: number;
  maxSiteIdParams: number;
}

export default class PickupClient {
  private apiClient: ApiClient;

  private config: PickupClientConfig;

  constructor(apiClient: ApiClient, config: PickupClientConfig) {
    this.apiClient = apiClient;
    this.config = config;
  }

  public async list({
                      limit = this.config.resourcesPerPage,
                      offset = 0,
                      sort = '-updatedAt',
                      filters = {},
                      search = '',
                    }: PickupListParams, config?: ApiClientRequestConfig): Promise<PaginatedItems<PickupSummary>> {
    // pickups are large, requesting more than 150 might exceed 6MB lambda limits
    // @see https://flip-eng.atlassian.net/browse/GEPPES-2714
    const safeLimitMax = 150;
    if (limit > safeLimitMax) {
      logger.error(`PickupClient: list page[limit] of ${limit} is potentially unsafe`);
    }
    const safeLimit = limit && clamp(limit, 0, safeLimitMax);

    const formattedFilters: Record<string, unknown> = { ...filters };

    // this is a little ugly until we massage formatSearchParams into typescript.
    const params = formatSearchParams({
      limit: safeLimit,
      offset,
      sort,
      filters: formattedFilters,
      search,
    });

    if (filters['service.agreedServiceId'] === '' || !params['filter[siteId]']) {
      // return empty set if no service ID is selected
      return {
        items: [],
        total: 0,
        offset,
        pageSize: safeLimit,
        numPages: 0,
      };
    }

    // this filter is required to determine whether the user is authorised to
    // retrieve the matching resources
    // todo: once the back-end have a proper 'all sites' implementation, this will be required
    // if (!params['filter[organisationId]']) {
    //   throw new Error('Pickup listing must include an organisation id filter');
    // }

    // we have a limit of n site IDs
    const maxSiteIds = this.config.maxSiteIdParams as number;
    if (maxSiteIds && params['filter[siteId]'] && params['filter[siteId]'].split(',').length > maxSiteIds) {
      throw new SiteIdLimitError(params['filter[siteId]'].split(',').length, maxSiteIds);
    }

    const response = await this.apiClient.query<operations['getPickups']['responses']['200']['content']['application/json']>(
      '/v0/pickups',
      {
        ...config,
        params,
      },
    );

    return mapPickupSearchResponse(response);
  }

  public async fetchById({ id }: { id: string }): Promise<{ pickup: Pickup }> {
    const response = await this.apiClient.get<operations['viewPickup']['responses']['200']['content']['application/json']>(`/v0/pickups/${id}`);

    return {
      pickup: mapPickupGetResponse(response.data.data),
    };
  }

  public async create(pickup: PickupFormData): Promise<PickupUpdateResponse> {
    const requestPickup = cloneDeep(pickup); // keep an immutable copy of pickup for server errors

    const request = mapPickupFormDataToPickupSaveRequest(requestPickup);

    let response: ApiClientResponse<operations['createPickup']['responses']['201']['content']['application/json']>;
    try {
      response = await this.apiClient.post('/v0/pickups', request);
    } catch (error) {
      if (error instanceof ValidationError) {
        throw mapCreatePickupServerErrors(error, requestPickup);
      }
      throw error;
    }

    logger.debug('[SenderService] Pickup created', {}, { request, response });

    // Return pickup
    const pickupData = response.data.data;
    return {
      id: pickupData.id,
      serviceOptions: pickupData.services.map(mapPickupServiceOption),
      consignments: pickupData.consignments?.map(mapPickupConsignmentUpdated),
    };
  }

  public async update(pickup: PickupFormData): Promise<PickupUpdateResponse> {
    const requestPickup = cloneDeep(pickup); // keep an immutable copy of pickup for server errors
    const request = mapPickupFormDataToPickupSaveRequest(requestPickup);

    let response: ApiClientResponse<operations['updatePickup']['responses']['200']['content']['application/json']>;
    try {
      response = await this.apiClient.put(`/v0/pickups/${pickup.id}`, request);
    } catch (error) {
      if (error instanceof ValidationError) {
        throw mapCreatePickupServerErrors(error, requestPickup);
      }
      throw error;
    }

    logger.debug('[SenderService] Pickup updated', {}, { pickupId: pickup.id, request, response });

    const pickupData = response.data.data;
    return {
      id: pickupData.id,
      serviceOptions: pickupData.services.map(mapPickupServiceOption),
      consignments: pickupData.consignments?.map(mapPickupConsignmentUpdated),
    };
  }

  public async request(pickup: PickupFormData, service: PickupService): Promise<{ id: UUID }> {
    const response = await this.apiClient.post<operations['updatePickup']['responses']['200']['content']['application/json']>(
      `/v0/pickups/${pickup.id}/requests`,
      {
        data: {
          service: {
            agreedServiceId: service.agreedServiceId,
          },
          dangerousGoodsDeclaration: pickup.dangerousGoodsDeclaration,
        },
      },
    );

    logger.debug('[SenderService] Pickup requested', {}, { pickupId: pickup.id, response });

    const pickupData = response.data.data;
    return {
      id: pickupData.id,
    };
  }
}
