<template>
  <CarrierPickupDetail
    :status-icon="IconStatusSuccess"
    :pickup="pickupDetails"
    :loading="isLoadingDetails"
  >
    <template #title>
      Pickup request:<br>
      {{ pickupDetails.reference }}<br>
      has been confirmed.
    </template>

    <FormFieldWrapper label="Pickup confirmation reference" optional>
      <ControlText
        v-model="confirmationReference"
        placeholder="Add a confirmation reference"
        :disabled="isPickupActioned"
        data-testref="confirm-ref-input"
        sensitive
      />
    </FormFieldWrapper>
    <div v-if="!isPickupActioned" class="buttons is-right">
      <button type="button" class="button" data-testref="confirm-btn" @click="confirm(token, confirmationReference)">Add reference</button>
    </div>
  </CarrierPickupDetail>
</template>

<script setup>
  import { Temporal } from '@js-temporal/polyfill';
  import { useTitle } from '@vueuse/core';
  import { computed, h, onMounted, ref } from 'vue';

  import ControlText from '@/shared/components/controls/ControlText.vue';
  import FormFieldWrapper from '@/shared/components/controls/wrappers/FormFieldWrapper.vue';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import IconSuccess from '@/shared/icons/svgs/success.svg';

  import CarrierPickupClient from '../apiClient/carrierPickupClient';
  import CarrierPickupDetail from '../components/CarrierPickupDetail.vue';

  const props = defineProps({
    token: { type: String, required: true },
  });

  const pageTitle = useTitle('View pickup response', {
    // When navigating out, we don't want the new route title to be overridden by the old title.
    restoreOnUnmount: false,
  });

  const IconStatusSuccess = h(SvgIcon, { style: 'color: var(--success-100);', svg: IconSuccess });

  const confirmationReference = ref('');
  const pickupDetails = ref(null);
  const isLoadingDetails = ref(false);

  const isPickupActioned = computed(() => !!pickupDetails.value.confirmation?.carrierReference);

  async function confirm(token, reference) {
    const response = await CarrierPickupClient.confirm({ token: props.token, confirmationReference: reference });

    if (response.error) {
      throw new Error('error in pickup action ');
    }

    if (reference) {
      pickupDetails.value.confirmation = { carrierReference: reference, confirmedAt: Temporal.Now.instant() };
    }
  }

  onMounted(async () => {
    // load pickup details
    try {
      isLoadingDetails.value = true;

      pickupDetails.value = await CarrierPickupClient.fetch({ token: props.token });
    } finally {
      isLoadingDetails.value = false;
    }

    pageTitle.value = `View pickup response: ${pickupDetails.value.reference}`;

    if (pickupDetails.value.confirmation?.carrierReference) {
      confirmationReference.value = pickupDetails.value.confirmation.carrierReference;
    }
  });
</script>
