import { modelWithHighlightFactory } from '@/shared/models/helpers/ModelWithHighlight';
import type { NewShippingItemPreset, PackagingType, ShippingItemPreset } from '@/shared/models/ShippingItemPreset';
import { shippingItemPresetFactory } from '@/shared/models/ShippingItemPreset';

import mapPaginatedResponse from '@/shared/services/api-client/helpers/mapPaginatedResponse';
import MappingError from '@/shared/services/errors/MappingError';
import { operations } from '@/shared/services/schema/geppetto-org/shippingitempresets.schema';

type ShippingItemPresetGetResponse = operations['getPreset']['responses']['200']['content']['application/json'];

export const mapShippingItemPresetGetResponse = ({ data }: ShippingItemPresetGetResponse) => shippingItemPresetFactory.create(data);

const createShippingItemPresetWithHighlight = modelWithHighlightFactory(shippingItemPresetFactory.create);

type ShippingItemPresetSearchResponse = operations['searchPresets']['responses']['200']['content']['application/json'];

export const mapShippingItemPresetSearchResultResponse =
  (response: ShippingItemPresetSearchResponse) => mapPaginatedResponse(
    (item) => createShippingItemPresetWithHighlight(item as ShippingItemPreset),
    response,
  );

type CreateShippingItemPresetPayload = operations['createPreset']['requestBody']['content']['application/json'];
export const mapNewShippingItemPresetToCreateShippingItemPresetPayload = (
  preset: NewShippingItemPreset,
): CreateShippingItemPresetPayload => {
  if (!preset.siteId
    || !preset.description
    || !preset.packagingType
    || !preset.quickAccessCode) {
    // this should never happen unless front-end validation fails
    throw new MappingError('ShippingItemPreset was created without all required fields');
  }

  return {
    data: {
      siteId: preset.siteId,
      quickAccessCode: preset.quickAccessCode,
      packagingType: preset.packagingType as PackagingType,
      description: preset.description,
      length: preset.length ? Math.round(preset.length) : undefined,
      width: preset.width ? Math.round(preset.width) : undefined,
      height: preset.height ? Math.round(preset.height) : undefined,
      weight: preset.weight ? Math.round(preset.weight) : undefined,
    },
  };
};


type UpdateShippingItemPresetPayload = operations['updatePreset']['requestBody']['content']['application/json'];
export const mapShippingItemPresetToUpdateShippingItemPresetPayload = (
  preset: ShippingItemPreset,
): UpdateShippingItemPresetPayload => {
  if (!preset.siteId
    || !preset.description
    || !preset.packagingType
    || !preset.quickAccessCode) {
    // this should never happen unless front-end validation fails
    throw new MappingError('ShippingItemPreset was created without all required fields');
  }

  return {
    data: {
      quickAccessCode: preset.quickAccessCode,
      packagingType: preset.packagingType as PackagingType,
      description: preset.description,
      length: preset.length ? Math.round(preset.length) : undefined,
      width: preset.width ? Math.round(preset.width) : undefined,
      height: preset.height ? Math.round(preset.height) : undefined,
      weight: preset.weight ? Math.round(preset.weight) : undefined,
    },
  };
};
