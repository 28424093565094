import { ModelWithHighlight } from '@/shared/models/helpers/ModelWithHighlight';
import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import ListingFilter from '@/shared/models/ListingFilters';
import type { NewShippingItemPreset, ShippingItemPreset } from '@/shared/models/ShippingItemPreset';
import { shippingItemPresetFactory } from '@/shared/models/ShippingItemPreset';
import ApiClient, { ApiClientRequestConfig } from '@/shared/services/api-client';
import { ApiClientError, ValidationError } from '@/shared/services/api-client/errors';
import MappingError from '@/shared/services/errors/MappingError';
import InvalidShippingItemPresetError from '@/shared/services/org/errors/InvalidShippingItemPresetError';
import { components, operations } from '@/shared/services/schema/geppetto-org/shippingitempresets.schema';

import {
  mapNewShippingItemPresetToCreateShippingItemPresetPayload,
  mapShippingItemPresetGetResponse,
  mapShippingItemPresetSearchResultResponse, mapShippingItemPresetToUpdateShippingItemPresetPayload,
} from './mappers/shippingItemPresetsMappers';

type PackagingType = components['schemas']['PackagingType'];
// this list must be kept in sync with the PackagingType definition in the schema
const packagingTypes = ['Carton', 'Satchel', 'Pallet', 'Skid', 'Crate', 'Stillage', 'Bulky', 'JiffyBag', 'Bag', 'Item', 'Other'];

function isPackagingType(packagingType: string): packagingType is PackagingType {
  return packagingTypes.includes(packagingType);
}

export interface ShippingItemPresetSearchParams extends Omit<ListingFilter, 'filters'> {
  siteId: UUID;
  packagingType?: string;
  query?: string;
}

// GEPPIE-5005 display quickAccessCode conflict as a validation error
function mapConflictAsValidationError(error: ApiClientError): ApiClientError {
  const conflictError = error.apiErrors?.find(e => e.code === 'conflict' && e.detail?.includes('quick access code already exists'));
  if (conflictError) {
    conflictError.source = { pointer: '/data/quickAccessCode' };
    conflictError.detail = 'Code already exists, try a different one';
    return new ValidationError(error);
  }
  return error;
}

export default class ShippingItemPresetsClient {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  public async getShippingItemPreset({ id }: { id: UUID }): Promise<ShippingItemPreset> {
    type ShippingItemPresetResponse = operations['getPreset']['responses']['200']['content']['application/json'];
    const { data } = await this.apiClient.get<ShippingItemPresetResponse>(`/shipping-item-presets/${id}`);
    return mapShippingItemPresetGetResponse(data);
  }

  public async getShippingItemPresetByQAC({
    quickAccessCode,
    siteId,
  }: {
    quickAccessCode: string,
    siteId: UUID,
  }): Promise<ShippingItemPreset | null> {
    const result = await this.searchShippingItemPreset({
      siteId,
      query: quickAccessCode,
      limit: 9000, // real ultimate power
    });

    const preset = result.items.filter((item: ShippingItemPreset) => item.quickAccessCode === quickAccessCode)[0];

    return preset ? shippingItemPresetFactory.create(preset) : null;
  }

  public async createShippingItemPreset(preset: NewShippingItemPreset): Promise<ShippingItemPreset> {
    if (!preset.siteId
      || !preset.description
      || !preset.packagingType
      || !preset.quickAccessCode) {
      // this should never happen from the UI unless front-end validation fails
      // this may happen for user CSV imports
      const requiredFields = ['siteId', 'description', 'packagingType', 'quickAccessCode'];
      throw new InvalidShippingItemPresetError({
        details: requiredFields
          .filter(required => !preset[required as keyof typeof preset])
          .map(required => `${required} is required`),
      });
    }

    try {
      type ShippingItemPresetCreateResponse = operations['createPreset']['responses']['201']['content']['application/json'];
      const response = await this.apiClient.post<ShippingItemPresetCreateResponse>(
        '/shipping-item-presets',
        mapNewShippingItemPresetToCreateShippingItemPresetPayload(preset),
      );

      if (response.status !== 201) {
        logger.warn('POST /shipping-item-presets resulted in non-201 status');
      }

      return shippingItemPresetFactory.create({
        // typescript has trouble recognising spread so manually pass all required fields
        ...preset,
        siteId: preset.siteId,
        description: preset.description,
        packagingType: preset.packagingType,
        quickAccessCode: preset.quickAccessCode,
        id: response.data.data.id,
      });
    } catch (error) {
      if (error instanceof ApiClientError) {
        throw mapConflictAsValidationError(error);
      }
      throw error;
    }
  }

  public async updateShippingItemPreset(preset: ShippingItemPreset): Promise<void> {
    const { id } = preset;
    try {
      type ShippingItemPresetUpdateResponse = operations['updatePreset']['responses']['202']['content'];
      const response = await this.apiClient.put<ShippingItemPresetUpdateResponse>(
        `/shipping-item-presets/${id}`,
        mapShippingItemPresetToUpdateShippingItemPresetPayload(preset),
      );

      if (response.status !== 202) {
        logger.warn('PUT /shipping-item-presets/:id resulted in non-202 status', { id });
      }
    } catch (error) {
      if (error instanceof ApiClientError) {
        throw mapConflictAsValidationError(error);
      }
      throw error;
    }
    // if it doesn't throw, success!
  }

  public async deleteShippingItemPreset({ id }: { id: string }): Promise<void> {
    type ShippingItemPresetDeleteResponse = operations['deletePreset']['responses']['204']['content'];
    const response = await this.apiClient.delete<ShippingItemPresetDeleteResponse>(
      `/shipping-item-presets/${id}`,
    );

    if (response.status !== 204) {
      logger.warn('DELETE /shipping-item-presets/:id resulted in non-204 status', { id });
    }
    // if it doesn't throw, success!
  }

  public async searchShippingItemPreset(params: ShippingItemPresetSearchParams, config?: ApiClientRequestConfig):
    Promise<PaginatedItems<ModelWithHighlight<ShippingItemPreset>>> {
    if (params.packagingType !== undefined && !isPackagingType(params.packagingType)) {
      throw new MappingError(`${params.packagingType} is not a valid packagingType`);
    }

    const apiParams: operations['searchPresets']['parameters']['query'] = {
      'filter[siteId]': params.siteId,
      'filter[packagingType]': params.packagingType,
      q: params.query,
      'page[limit]': params.limit,
      'page[offset]': params.offset,
      sort: params.sort,
      'highlight[start]': '{{',
      'highlight[stop]': '}}',
    };
    type ShippingItemPresetSearchResponse = operations['searchPresets']['responses']['200']['content']['application/json'];
    const response = await this.apiClient.query<ShippingItemPresetSearchResponse>('/shipping-item-presets', { ...config, params: apiParams });

    return mapShippingItemPresetSearchResultResponse(response.data);
  }
}
