import uniqueId from 'lodash/uniqueId';
import type { ComponentPublicInstance } from 'vue';
import { getCurrentInstance, inject, provide } from 'vue';

import {
  contextInjectionKeyConfig,
  contextInjectionKeyDisplayHandlers,
  ErrorDisplayHandler,
} from './config';
import displayError from './displayError';
import errorStore from './errorStore';
import { errorIsCritical, errorIsDisplayed, errorIsHandled } from './helpers';

type ErrorCapturedHookPayload<TError = Error> = { error: TError, vm: ComponentPublicInstance | null, info: string };

const errorHelpers = {
  errorIsCritical,
  errorIsDisplayed,
  errorIsHandled,
  markAsHandled(error: Error) { error._handled = true; },
  markAsDisplayed(error: Error) { error._displayed = true; },
  markAsCritical(error: Error) { error._critical = true; },
};

export interface ErrorBoundaryConfig {
  errorDisplayHandlers?: ErrorDisplayHandler[];
  name: string;
  onErrorCaptured?: <TError extends Error>(hookPayload: ErrorCapturedHookPayload<TError>, helpers: typeof errorHelpers) => void,
  useExactName?: boolean;
  manualDisplay?: boolean;
}

function isValidErrorBoundary(errorBoundaryName: string) {
  return errorBoundaryName ? errorStore.hasErrorBoundary(errorBoundaryName) : false;
}

export default function useErrorBoundary(errorBoundaryConfig: ErrorBoundaryConfig) {
  const instance = getCurrentInstance();

  // if a component creates a new error scope, add a unique suffix so every invocation gets a
  // unique namespace. this is important if a single view is being re-mounted within a transition,
  // as the old boundary will be unregistered after the new boundary is registerd, potentially
  // destroying the new boundary.
  // in the case of an ErrorBoundary component, it must use the exact name that it's configured with.
  const errorScope = errorBoundaryConfig.useExactName
    ? errorBoundaryConfig.name
    : `${errorBoundaryConfig.name}-${uniqueId()}`;

  if (instance) {
    instance.errorHandle = <TError extends Error>(error: TError, vm: ComponentPublicInstance | null, info: string) => {
      const {
        onErrorCaptured,
      } = errorBoundaryConfig;

      if (!isValidErrorBoundary(errorScope)) {
        logger.warn(`errorBoundary ${errorScope} was not registered`, { error }, { vm, info });
        return true;
      }

      if (onErrorCaptured) onErrorCaptured<TError>({ error, vm, info }, errorHelpers);

      if (errorIsCritical(error) && !errorIsDisplayed(error)) {
        errorStore.setCriticalErrorBoundary(errorScope, error);
      }

      if (!errorStore.canErrorBoundaryDisplayErrors(errorScope)) {
        logger.warn(`errorBoundary ${errorScope} cannot display errors`, { error }, { vm, info });
        return error;
      }

      displayError(error, errorScope);

      // Continue processing the error
      return true;
    };
  }

  const errorHandlingConfig = inject(contextInjectionKeyConfig);
  const injectedDisplayHandlers = inject(contextInjectionKeyDisplayHandlers, undefined);

  // if an outer error boundary has provided its own local display handlers, they will include the
  // global handlers already
  const inheritedDisplayHandlers = injectedDisplayHandlers || errorHandlingConfig?.errorDisplayHandlers || [];

  // TODO this should probably be required, but causes problems with modals that don't support inject
  // if (!errorHandlingConfig) throw new Error('useErrorBoundary must be used with errorHandling plugin');

  // provide local display handlers then inherited handlers to all sub-components that might
  // display errors
  const displayHandlers = [
    // local handlers first so they take precedence when matching
    ...(errorBoundaryConfig.errorDisplayHandlers || []),
    ...inheritedDisplayHandlers,
  ];
  provide(contextInjectionKeyDisplayHandlers, displayHandlers);

  return {
    errorBoundary: {
      name: errorScope,
      onErrorCaptured: errorBoundaryConfig.onErrorCaptured,
      clearErrors() {
        errorStore.clearErrors(errorScope);
      },
      manualDisplay: errorBoundaryConfig.manualDisplay,
    },
  };
}
