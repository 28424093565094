import { Temporal } from '@js-temporal/polyfill';
import * as validators from '@vuelidate/validators';
import { helpers } from '@vuelidate/validators';
import { withMessage, withParams } from 'veritas';
import { toValue, unref } from 'vue';

import { formatDate } from '@/shared/DateTime/formatters';

import defaultValidationMessages from '../default-validation-messages';

export {
  dateRangeEndNotAfter,
  dateRangeStartNotBefore,
  maxDateRangeSpan,
  dateRangeStartEndInWeekDays,
  dateRangeStartNotAfter,
} from './dateRangeValidations';

export {
  minTimeRangeSpan,
  timeRangeWithinRange,
  timeRangeWithinRanges,
  validTimeRange,
} from './timeRangeValidations';

export const required = withMessage(
  defaultValidationMessages.required,
  validators.required,
);

export const requiredIf = (param) => withParams(
  { param },
  withMessage(
    defaultValidationMessages.required,
    validators.requiredIf(param),
  ),
);

export const numeric = withMessage(
  defaultValidationMessages.numeric,
  validators.numeric,
);

export const integer = withMessage(
  defaultValidationMessages.integer,
  validators.integer,
);

export const sameAs = (comparison) => withParams(
  { comparison },
  withMessage(
    'values must match', // we don't mention the comparison value in case its sensitive.
    validators.sameAs(comparison),
  ),
);

export const email = withMessage(
  defaultValidationMessages.email,
  validators.email,
);

export const maxLength = (max) => withMessage(
  `cannot be more than ${max} characters`,
  validators.maxLength(max),
);

export const minLength = (min) => withMessage(
  `cannot be less than ${min} characters`,
  validators.minLength(min),
);

export const maxValue = (max) => withParams(
  { max },
  withMessage(
    ({ $params }) => `must be ${$params.max} or less`,
    validators.maxValue(max),
  ),
);

export const minValue = (min) => withParams(
  { min },
  withMessage(
    ({ $params }) => `must be ${$params.min} or greater`,
    validators.minValue(min),
  ),
);

export const greaterThan = (min) => withParams(
  { min },
  withMessage(
    ({ $params }) => `must be value greater than ${$params.min}`,
    (value) => !helpers.req(value) || value > min,
  ),
);

export const lessThan = (max) => withParams(
  { max },
  withMessage(
    ({ $params }) => `must be less than ${$params.max}`,
    (value) => !helpers.req(value) || value < max,
  ),
);

export const minDate = (date) => withParams(
  { date },
  withMessage(
    ({ $params }) => `must be greater than ${$params.date}`,
    (value) => value >= date,
  ),
);

const phoneValidation = (value) => {
  const phoneNo = value ? value.replace(/[ ()]/g, '') : null;
  const check = (checkFn, errorMessage) => (!checkFn(value) ? errorMessage : '');

  if (!helpers.req(phoneNo)) {
    return '';
  }

  if (/^1300\d+$/.test(phoneNo) && phoneNo.length > 6) {
    return check(() => phoneNo.length === 10, '1300 numbers must have 10 digits');
  }
  if (/^1800\d+$/.test(phoneNo)) {
    return check(() => phoneNo.length === 10, '1800 numbers must have 10 digits');
  }
  if (/^13\d+$/.test(phoneNo)) {
    return check(() => phoneNo.length === 6, '13 numbers must be 6 digits');
  }
  if (/^0[23478]\d+$/.test(phoneNo)) {
    return check(() => phoneNo.length === 10, 'must have 10 digits');
  }
  if (/^[23478]\d+$/.test(phoneNo)) {
    return check(() => phoneNo.length === 9, 'must have 9 digits');
  }

  // Do not attempt to validate other phone numbers, let's defer to the backend for these.
  // @see https://flip-eng.atlassian.net/browse/GEPPES-521
  // @see https://flip-eng.atlassian.net/browse/GEPPES-3941
  return '';
};

export const phone = withMessage(
  ({ $model }) => phoneValidation($model),
  (value) => !phoneValidation(value),
);

export const noSpaces = withMessage(
  'cannot contain spaces',
  (value) => /^\S*$/.test(value),
);

export const noLeadingSpace = withMessage(
  'cannot have leading spaces',
  (value) => !(/^\s+\w*/.test(value)),
);

export const noTrailingSpace = withMessage(
  'cannot have trailing spaces',
  (value) => /.*[^\s]+$/.test(value),
);

export const mustEqual = (requiredValue) => withParams(
  { requiredValue },
  withMessage(
    ({ $params }) => `must be ${$params.requiredValue}`, // todo: might requiredValue be sensitive?
    (value) => value === requiredValue,
  ),
);

export const notUndefined = withMessage(
  defaultValidationMessages.required,
  (value) => typeof value !== 'undefined',
);

export const notNull = withMessage(
  defaultValidationMessages.required,
  (value) => value !== null,
);

export const requiredPlainDate = withMessage(
  defaultValidationMessages.validDate,
  (value) => !!value && value instanceof Temporal.PlainDate,
);

export const dateNotBefore = (refDate) => withParams(
  { refDate: unref(refDate) },
  withMessage(
    ({ $params }) => `date must be after ${formatDate($params.refDate)}`,
    (value) => !value || value?.since(unref(refDate)).days > -1,
  ),
);

export const optionalDateNotBefore = (refDate) => withParams(
  { refDate: unref(refDate) },
  withMessage(
    ({ $params }) => `date must be after ${formatDate($params.refDate)}`,
    (value) => (value ? (value.since(unref(refDate)).days > -1) : true),
  ),
);

export const atLeastOneOption = (optionType) => withParams(
  { optionType },
  withMessage(
    ({ $params }) => `At least one ${$params.optionType} must be selected`,
    (value) => value?.length >= 1,
  ),
);

export const uniqueTags = (allTags) => withMessage(
'values must be unique',
  (value) => {
    const tags = toValue(allTags);
    if (!helpers.req(value) || !Array.isArray(tags) || !tags.length) return true;
    if (tags.filter(v => v.toLowerCase() === value.toLowerCase()).length > 1) return false;
    return true;
  },
);
