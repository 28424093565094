import { Temporal } from '@js-temporal/polyfill';
import omit from 'lodash/omit';

import { shake } from 'radash';

import { Interval } from '@/shared/DateTime';
import { instantFromDateAndTime } from '@/shared/DateTime/mappers';
import { type NewConsignment, quoteFactory, type QuoteSet } from '@/shared/models';
import { isVerifiedAddress } from '@/shared/models/ConsignmentAddressee';
import { removeEmptyEvaluations } from '@/shared/models/Quote/helpers';
import MappingError from '@/shared/services/errors/MappingError';
import { components, operations } from '@/shared/services/schema/geppetto-sender-app/quote-sets.schema';

type CreateQuoteSetRequest = operations['createQuoteSet']['requestBody']['content']['application/json'];
export const consignmentToCreateQuoteSetRequest = (consignment: NewConsignment): CreateQuoteSetRequest => {
  if (!consignment.siteId) throw new MappingError('packaging is required');
  if (!consignment.sender.addressId) throw new MappingError('sender.addressId is required');
  if (!consignment.receiver.addressId) throw new MappingError('receiver.addressId is required');

  const createRequest: CreateQuoteSetRequest = {
    data: {
      dispatchDate: consignment.dispatchDate!,
      lineItems: consignment.lineItems.map(lineItem => {
        if (!lineItem.description) throw new MappingError('packaging is required');
        if (!lineItem.height) throw new MappingError('height is required');
        if (!lineItem.length) throw new MappingError('length is required');
        if (!lineItem.packagingType) throw new MappingError('packagingType is required');
        if (!lineItem.weight) throw new MappingError('weight is required');
        if (!lineItem.width) throw new MappingError('width is required');

        return {
          description: lineItem.description,
          height: Math.round(lineItem.height),
          itemReference: lineItem.reference,
          length: Math.round(lineItem.length),
          packagingType: lineItem.packagingType,
          quantity: Math.round(lineItem.quantity),
          weight: Math.round(lineItem.weight),
          width: Math.round(lineItem.width),
        };
      }),
      receiver: {
        addressId: consignment.receiver.addressId,
        residential: consignment.receiver.residential,
      },
      sender: { addressId: consignment.sender.addressId },
      siteId: consignment.siteId,
    },
  };

  if (consignment.lineItems.some(
    (lineItem) => lineItem.dangerousGoods && lineItem.dangerousGoods.length > 0,
  )) {
    createRequest.data.hasDangerousGoods = true;
  }

  if (consignment.receiver.deliveryTimeSlot?.requiresDTS) {
    if (!consignment.receiver.deliveryTimeSlot?.slot?.dateRange?.length) {
      throw new MappingError('DeliveryTimeSlot DateRange is required', consignment.id);
    }
    if (!consignment.receiver.deliveryTimeSlot?.slot?.timeRange?.start) throw new MappingError('DeliveryTimeSlot.timeRange.start Date[0] is required');
    if (!consignment.receiver.deliveryTimeSlot?.slot?.timeRange?.end) throw new MappingError('DeliveryTimeSlot.timeRange.end Date[0] is required');
    if (!isVerifiedAddress(consignment.receiver.address)) throw new MappingError('receiver.address is not a valid address');
    if (!consignment.receiver.address?.timeZone) throw new MappingError('receiver.address.timezone is required');

    createRequest.data.receiver.deliveryTimeSlot = {
      window: Interval.from({
        start: instantFromDateAndTime(
          consignment.receiver.deliveryTimeSlot?.slot?.dateRange[0],
          consignment.receiver.deliveryTimeSlot?.slot?.timeRange.start,
          consignment.receiver.address.timeZone,
        ),
        end: instantFromDateAndTime(
          consignment.receiver.deliveryTimeSlot?.slot?.dateRange[0],
          consignment.receiver.deliveryTimeSlot?.slot?.timeRange?.end,
          consignment.receiver.address.timeZone,
        ),
      }).toString(),
      recurrences: consignment.receiver.deliveryTimeSlot?.slot?.dateRange[1]
        ? consignment.receiver.deliveryTimeSlot?.slot?.dateRange[0].until(consignment.receiver.deliveryTimeSlot.slot?.dateRange[1]).days
        : 0,
    };
  }


  return createRequest;
};

export const clientQuoteSetToQuoteSet = (quoteSet: components['schemas']['QuoteSet'], consignment: NewConsignment): QuoteSet => ({
  id: quoteSet.id,
  quotes: quoteSet.quotes.map(quote => {
    const evaluation = {
      ...omit(quote.evaluation, ['invalidDeliveryTimeSlotStartDate']),
      ...(quote.evaluation?.invalidDeliveryTimeSlotStartDate?.earliestPermittedDate
          ? {
            invalidDeliveryTimeSlotStartDate: {
              earliestPermittedDate: Temporal.PlainDate.from(quote.evaluation.invalidDeliveryTimeSlotStartDate.earliestPermittedDate),
              dateRange: consignment.receiver.deliveryTimeSlot?.slot?.dateRange,
            },
          }
          : {}
      ),
    };
    return quoteFactory.create({
      id: quote.id,
      agreedServiceId: quote.agreedServiceId,
      carrierId: quote.carrierId,
      carrierServiceId: quote.carrierServiceId,
      quoteSetId: quote.quoteSetId,
      eta: quote.transitGuideDays === 25 ? '' : quote.etaDate, // GEPPES-2420 - Duke fallback transit guide
      chargesBreakdown: quote.chargesBreakdown,
      transitGuideDays: quote.transitGuideDays,
      currency: quote.currency,
      freight: quote.freight,
      fees: quote.charges,
      net: quote.net,
      tax: quote.tax,
      total: quote.total,
      selectable: quote.isSelectable,
      recommended: quote.isSelectable && !Object.keys(removeEmptyEvaluations(evaluation)).length,
      evaluation: shake(quote.evaluation),

      createdAt: quote.createdAt,
    });
  }),
  createdAt: quoteSet.createdAt,
});
