import { Order, OrderSummary } from '@/shared/models';

import { operations } from '@/shared/services/schema/geppetto-sender-app/orders.schema';

import mapPaginatedResponse from '../../api-client/helpers/mapPaginatedResponse';

type SearchOrdersResponse = operations['getOrders']['responses']['200']['content']['application/json'];
type ViewOrderResponse = operations['viewOrder']['responses']['200']['content']['application/json'];

export const mapOrdersSearchResults =
  (response: SearchOrdersResponse) => mapPaginatedResponse((order: OrderSummary) => order, response);

  export const mapOrderViewResponse = (response: ViewOrderResponse): Order => {
    const order = response.data;
    return {
      ...order,
      agreedService: order.agreedServiceId ? {
        id: order.agreedServiceId,
      } : undefined,
    };
  };
